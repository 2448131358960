@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../mixins' as top-navigation;

$top-nav-height: 5.6rem;

@mixin visibility-hidden() {
  visibility: hidden;

  /* This is only a trick to move
   the element under the navbar, so the slow element
   visibility change to hidden (due to recursion) is
   not visible to user */
  z-index: -1;
}

.sisu-navigation {
  position: relative;
  background-color: colors.$dark-gray;
  min-height: $top-nav-height;
  overflow-x: clip;
  white-space: nowrap;

  &__link {
    &:focus {
      @include focus.fudis-focus-generic;
    }

    &--default {
      display: inline-block;
      padding: 0 spacing.$spacing-md;
      height: $top-nav-height;
      text-decoration: none;
      line-height: $top-nav-height;
      color: colors.$white;
      font-size: typography.$body-text-md-font-size;
      font-weight: typography.$font-weight-regular;

      &:hover {
        background-color: colors.$middle-gray;
        color: colors.$white;
      }

      &--active,
      &--active:hover {
        background-color: colors.$white;
        cursor: default;
        color: colors.$dark-gray;
      }
    }

    &--mobile {
      display: inline-block;
      padding: 0 spacing.$spacing-md;
      width: 100%;
      height: $top-nav-height;
      text-decoration: none;
      line-height: $top-nav-height;
      color: colors.$dark-gray;
      font-size: typography.$body-text-md-font-size;
      font-weight: typography.$font-weight-medium;

      &:hover {
        background-color: colors.$primary-color-light;
      }
    }

    &__logo {
      height: 100%;
    }

    &__mobile-menu-button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      padding: 0 spacing.$spacing-md;
      height: $top-nav-height;

      &__icon {
        pointer-events: none;
      }
    }
  }

  &__logo-wrapper {
    display: inline-block;

    &__divider {
      display: inline-block;
      border-left: spacing.$pixel-1 solid colors.$white;
      height: 2.6rem;
      vertical-align: middle;
    }

    &--hidden { @include visibility-hidden; }
  }

  &__links {
    display: inline-block;

    /* This position relative is only here because we need this to render
     after the :before tag in sisu-navigation class that is used in dev-enviroments */
    position: relative;

    &--hidden { @include visibility-hidden; }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      &--default {
        display: flex;
        margin: 0;
        padding: 0 spacing.$spacing-xs 0 0;
      }

      &--mobile {
        display: flex;
        flex-direction: column;
      }

      &__item {
        margin: 0;
        padding: 0;

        &--mobile {
          border-right: spacing.$pixel-1 solid colors.$dark-gray;
          border-bottom: spacing.$pixel-1 solid colors.$light-gray;
          border-left: spacing.$pixel-1 solid colors.$dark-gray;

          &:last-child {
            border-bottom-color: colors.$light-gray;
          }

          &__pointer {
            pointer-events: none;
          }
        }
      }
    }
  }

  &__side {
    position: absolute;
    top: 0;
    right: 0;
    height: $top-nav-height;

    &--hidden { @include visibility-hidden; }

    &__items {
      display: flex;
    }
  }

  &__focus-mode-navigation {
    position: absolute;
    top: 0;
    left: 0;
    height: $top-nav-height;
  }

  &__mobile-menu {
    @include top-navigation.top-navigation-mobile-width;

    position: absolute;
    right: 0;
    z-index: 1022; // One notch higher than sticky alert. Otherwise user cannot navigate from mobile menu if there are overlapping alerts.
    border-top: 0;
    border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
    background-color: colors.$white;
    height: fit-content;

    /** Not a pretty fix, but this multiplying by 2 exists because of university-iframe-component,
     which is the same height as the top navigation. This way the menu stops neatly in the bottom
     of the screen. Can be probably done in a prettier fashion, but would need to tweak the other component as well.
     Hopefully this inspires someone to do the fix in the future. */
    max-height: calc(100vh - ($top-nav-height * 2));
    overflow-y: auto;

    &__user {
      @include fudis.body-text-md-regular;

      display: flex;
      align-items: center;
      border-right: spacing.$pixel-1 solid colors.$dark-gray;
      border-left: spacing.$pixel-1 solid colors.$dark-gray;
      background-color: colors.$extra-light-gray;
      padding: 0 spacing.$spacing-md;
      width: 100%;
      height: 4rem;

      &-name,
      &-role {
        display: block;
        margin: spacing.$spacing-zero;
      }
    }
  }
}



